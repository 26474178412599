.icon {
	margin-right: 10px;
}
.sectionLabel {
	margin-top: -14px;
}

.subSectionItem {
	margin-top: 8px;
}
.toggleBtnDiv {
	margin-top: 9px;
	margin-bottom: -5px;
}
.listItem {
	/* margin-top: -24px; */
	margin-bottom: -30px;
}

.searchBox {
	margin-top: 1vh;
	margin-bottom: 5vh;
	padding-right: -7vh;
	padding-left: -7vh;
}
.navContainer {
	padding-top: 5vh;
	border-radius: 0 30px 0 0;
	box-shadow: 0 3px 6px #00000029;
	background-color: #fff;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.navTitle {
	padding: 1.5vh 1vw;
	font-size: 0.7vw;
	font-family: var(--common-font-bold);
}

.navTitleExpanded {
	background: var(--blue);
	letter-spacing: 0.21px;
	color: #ffffff;
}

.navTitle img {
	width: 0.8vw;
	height: 0.8vw;
	margin-right: 1em;
}

.navTitleCollapsed {
	background-color: #ffffff;
	letter-spacing: 0.21px;
	color: #3b3b3b;
	padding-bottom: 3vh !important;
}

.navSection {
	background-color: #e9f5fd;
	padding: 1vh;
	min-height: 5vh;
	border-top: 1px solid #dadce0;
	overflow-y: auto;
}

.version {
	padding: 2em;
	text-align: center;
	width: 100%;
	font-family: var(--common-font-bold);
	font-size: 0.7vw;
}

.accordionSection {
	padding: 0;
	padding-top: 1em;
	border-bottom: 1px solid #ccc;
}

.accordionButton {
	padding-top: 0;
	padding-bottom: 0;
}

.accordionButton:focus {
	box-shadow: none !important;
}

.fullWidth {
	width: 100%;
}

.toggleButton {
	display: inline;
}
.subMenuContainer {
	padding: 10px;
}
.accordionContent2 {
	padding-right: 5vh;
	padding-left: 5vh;
}

.noItem {
	padding-bottom: 2vh;
}
