.container {
	border: 2px solid #f26122;
	border-radius: 50px;
	padding: 17px 23px 5px 23px;
	margin-bottom: 15px;
}

.subtext {
	color: #8b9095;
	font-size: 0.6vw;
	margin-bottom: 8px;
}

.inputContainer > div {
	padding-right: 15px;
}

.container label {
	font: normal normal 12px/18px Poppins-Regular;
	letter-spacing: 0px;
	color: #3b3b3b;
}

.container h1 {
	font: normal normal 22px/26px Wavehaus-SemiBold;
	letter-spacing: 0px;
	color: #3b3b3b;
}

.container h2 {
	font: normal normal 18px Wavehaus-SemiBold;
	letter-spacing: 0px;
	color: #3b3b3b;
}

.container p {
	font: normal normal 10px Wavehaus-SemiBold;
	letter-spacing: 0px;
	color: #aca9a9;
	margin-bottom: 5px;
	font-style: italic;
}
/*
.buttonDiv{
  padding: 20px;
  text-align: center;
} */

.textArea > div > textarea {
	background: #ffffff 0% 0% no-repeat padding-box !important;
	border: 1px solid #dadce0 !important;
	border-radius: 4px;
	height: 131px;
}

.headerContainer {
	text-align: left;
}

.modal {
	padding: 1vw;
}

.vspaced > * {
	margin-bottom: 2vh;
}

.sectionLabel {
	font-weight: bold;
	font-size: 0.8vw;
}

.footer {
	display: flex;
	justify-content: flex-end;
	padding: 0.1vmax;
	position: sticky;
	bottom: 0;
}

.footer .btn {
	min-width: 5vw;
}

.productName {
	max-height: 4.6vh !important;
}

.subtext {
	color: #969698;
	font-size: 0.8vw;
}
