.otpModal {
	align-self: center;
	border-radius: 0.25rem;
	width: fit-content;
	width: 40vw;
}

.larger {
	width: 23vw;
}

.otpModalContainer {
	align-self: center;
	top: -10vh;
	padding: 1vw;
}

.otpModalContent {
	padding: 1vw;
}

.otpIcon {
	fill: green;
	width: 8vw;
	margin-top: 5vh;
}

.otpModalIconContainer {
	display: flex;
	justify-content: center;
}

.otpModalBody > div {
	font-family: var(--common-font);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
	margin: 1em 0;
	margin-bottom: 50px;
}

.otpModalActions > div {
	display: flex;
	justify-content: center;
}

.otpModalHeader button {
	display: none;
}

.otpModalText {
	font-size: 18px;
	margin-top: 1vh;
	margin-bottom: 2vh;
}

.otpModalSubtext {
	/* width: 70%; 
	margin-bottom: 2vh;*/
	font-size: 1vw;
	
}

.otpModalSubtextBold:before {
    content: "\00a0";
}

.otpModalSubtextBold {
	display: inline;
	font-weight: bold;
}

.otpHeader {
	font-weight: bold;
	font-size: 1.2vmax;
	/* margin-bottom: 3px; */
}

.otpBody {
	font-size: 0.8vmax;
	margin-bottom: 30px;
}

.otpBodyWithPadding {
	padding-left: 1.5vmax;
	padding-right: 1.5vmax;
	font-size: 0.8vmax;
	margin-bottom: 30px;
}

.otpModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 320px;
	height: 240px;
	font-size: 13px !important;
}

.input{
	width: 32px;
	height: 32px;
	text-align: center;
	border: none;
	border-bottom: 1.5px solid #d2d2d2;
	margin: 0 10px;
}

.input{
	border-bottom: 1.5px solid orange;
	outline: none;
}

.resendOtpButtonsContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
  }
  
  .otpModalSubActions {
	display: flex;
	flex-direction: column;
	align-items: center;
  }
  
  .resendOtpButton {
	padding-top: 1%;
	margin-top: 10px;
  }
  .resendOtpViaEmailButton {
	padding-top: 0%;
	margin-top: 10px;
	margin-bottom: 50px;
  }