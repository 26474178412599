.th {
	display: flex;
	align-items: center;
	padding: 1.5vh 0.6vw;
	color: white;
	height: 100%;
	background-color: var(--blue);
	font-family: var(--common-font);
	font-size: min(0.8vw, 14px);
}

.th div:nth-child(1) {
	margin-right: 0.5em;
}

.th.sortable {
	cursor: pointer;
	padding-right: 0 !important;
}

.td {
	padding: 0.7em 0.6em;
	font-family: var(--common-font);
	font-size: min(0.8vw, 14px);
	/* display: flex; */
	align-items: center;
	word-wrap: break-word;
}

.sortIcon {
	margin-left: 0.1em;
}

.tr {
	align-items: stretch;
}
.tr:nth-child(even) .td {
	background: #f3faff !important;
}
.tr:nth-child(odd) .td {
	background: white !important;
}

.tr .td:first-child {
	border-left: 1px solid #dadfe2;
}

.tr .td:last-child {
	border-right: 1px solid #dadfe2;
}

.tr:last-child .td:first-child {
	border-bottom-left-radius: 10px;
}

.tr:last-child .td:last-child {
	border-bottom-right-radius: 10px;
	padding-bottom: 30px;
}

.trEvenColumn:nth-child(even) .td {
	background: white !important;
}
.trEvenColumn:nth-child(odd) .td {
	background: white !important;
}

* .tr .td {
	border-bottom: 1px solid #dadfe2;
}

.tbody {
	overflow-y: scroll;
	height: 50vh;
}

.statusPending {
	color: #f26122;
}
.statusApproved {
	color: #14bb02;
}
.statusDeclined {
	color: #d93025;
}

.paperContainer {
	padding: 1.5vmax;
	border-radius: 20px;
	justify-content: center;
	align-items: center;
}

.pagination {
	margin-top: 2vh;
}

.pagination > div {
	height: 30px;
	padding: 0;
}

.limitContainer {
	margin-right: 1vw;
}

.pageOptions {
	min-height: var(--input-min-height);
	font-family: var(--common-font);
}

.pageOptions button,
.pageOptions button:not(button:disabled):hover {
	color: var(--orange);
}

.pageOptions button:hover {
	background-color: #f3f3f3;
}

.pageOptions .pageActive {
	background-color: var(--orange);
	color: white;
	cursor: default;
	pointer-events: none;
}

.emptyRow {
	width: 100%;
	height: 3.5vh;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: min(0.8vw, 14px);
	font-family: var(--common-font);
	border-left: 1px solid #dadfe2;
	border-right: 1px solid #dadfe2;
}

.emptyRow:last-child {
	border-bottom: 1px solid #dadfe2;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

.paginationContainer {
	font-family: var(--common-font);
	font-size: var(--input-label-font-size);
}

.pageBtn {
	line-height: 0 !important;
}

.sortIcon {
	margin-left: 0.1vw;
}

.noResult {
	width: 100%;
	margin: 12px;
	text-align: center;
	display: inline-block;
}

.thead div[role='columnheader']:first-child div {
	border-top-left-radius: 7px;
}

.thead div[role='columnheader']:last-child div {
	border-top-right-radius: 7px;
}

.withPreHeader div[role='columnheader']:first-child div {
	border-top-left-radius: 0;
}

.withPreHeader div[role='columnheader']:last-child div {
	border-top-right-radius: 0;
}

.preHeader {
	border-top-left-radius: 7px;
	border-top-right-radius: 7px;
	background-color: var(--blue);
	display: flex;
	justify-content: flex-end;
	width: 100%;
	height: auto;
	padding: 10px 16px;
}

.trHeightForFields {
	height: 15vh;
}

.tableRow {
	background-color: #ffffff;
}

.tableRow:nth-child(odd) {
	background-color: #f3faff;
}

.tableAlignToFilter {
	padding: 0.7vmax !important;
}

.tableRowTd .td:last-child {
	padding-bottom: 0 !important;
}

.tableRowTd > .td {
	padding-bottom: 5px !important;
}

@media (max-width: 1440px) {
	.selectOptions {
		max-height: 75px;
		width: 50px;
		overflow-y: auto;
		min-width: fit-content !important;
	}
}
