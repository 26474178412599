.table {
	width: 100% !important;
	border-collapse: collapse;
}

.tableHeader {
	height: 40px !important;
	color: black !important;
	background-color: #e2e2e2 !important;
	font-weight: normal !important;
	padding: 10px;
}

.tableBody {
	height: 40px !important;
	margin-bottom: 15px !important;
	background-color: #ffffff;
}
.tableHeaderNoborder {
	height: 40px !important;
	font-weight: normal !important;
}
.tableBodyNoborder {
	height: 40px !important;
	font-weight: normal !important;
}

.shareBgColor {
	background-color: rgb(255, 233, 223);
	font-weight: bold !important;
}

.lineDevider {
	border: none;
	height: 2px;
	background-color: var(--blue);
	margin-bottom: -1px;
}

.tableTitle {
	padding-top: 20px;
	font-size: 1vw;
	font-family: var(--common-font-bold);
	margin-bottom: -3vh;
}

.inLine {
	margin-top: -38px !important;
	display: inline-block;
}

.inLineRegular {
	margin-bottom: -7.5vw;
	display: inline-block;
}

.headerContainer {
	/* to update */
	color: black;
}

.contentContainer {
	/* to update */
	color: black;
}

.modalContainer {
	max-width: 60rem !important;
}

.modalConfirmation {
	width: 30rem !important;
}

.bodyHeader {
	padding: 3vh 0 3vh 0;
}

.tableBordered {
	width: 100% !important;
	border-collapse: collapse;
	border: 2px solid rgb(212, 212, 212) !important;
	border-radius: 8px !important;
}

.tableSmall {
	width: 30rem !important;
}

.tableContainerStyle {
	border-radius: 8px !important;
	overflow-x: auto !important;
	max-width: 105vh !important;
	min-width: 83vh !important;
}

.tableContainerRegular {
	border-radius: 8px !important;
	overflow: hidden;
	max-width: 93vh !important;
}

.contentHeader {
	font-size: 2vh;
	margin-bottom: 1vh;
	margin-left: 1.9vh;
}

.tableRegular {
	margin-top: -1.5vh;
}