.headerContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-top: 24px;
	padding-bottom: 30px;
	margin-left: 24px;
	margin-right: 24px;
}

.subHeaderContainer {
	padding-top: 18px;
	padding-bottom: 28px;
	margin-left: 24px;
	margin-right: 24px;
}

.leftHeaderContainer {
	display: flex;
	flex: content;
	justify-content: start;
}

.rightHeaderContainer {
	display: flex;
}

.partnerDetails {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	margin: 10px 0 30px 20px;
}

.partnerNameContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.logoFieldContainer {
	border-radius: 50%;
	background-color: white;
	width: 5.5vw;
	height: 5.5vw;
	display: flex;
	font-size: 2vw;
	color: white;
	position: relative;
}

.partnerName {
	text-align: left;
	font-size: 17px;
	font-weight: bold;
	font-family: var(--common-font-bold);
	letter-spacing: 0px;
	color: #3b3b3b;
	opacity: 1;
}

.statusContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.statusLabel {
	text-align: left;
	letter-spacing: 0px;
	color: #3b3b3b;
	opacity: 1;
	margin-right: 7px;
	font-size: 12px;
}

.statusEnabled {
	color: var(--unnamed-color-14bb02);
	text-align: left;
	font-size: 12px;
	font-family: var(--common-font-bold);
	color: #14bb02;
	opacity: 1;
}

.statusDisabled {
	color: var(--unnamed-color-14bb02);
	text-align: left;
	font-size: 12px;
	font-family: var(--common-font-bold);
	color: #c84031;
	opacity: 1;
}

.transactionContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 14vw;
	height: 5vw;
	background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #dadce0;
	border-radius: 5px;
	opacity: 1;
	margin-left: 0.7vw;
	margin-right: 0;
	margin-top: 0.7vw;
	padding: 0 16px 0 8px;
}

.transactionHeaderText,
.transactionHeaderSubText {
	text-align: left;
	font-size: 0.7vw;
	letter-spacing: 0px;
	color: #3b3b3b;
	opacity: 1;
	padding: 1px 0 1px 0;
}

.transactionVolume,
.transactionCollection {
	text-align: left;
	font-size: 0.7vw;
	font-weight: bold;
	font-family: var(--common-font-bold);
	letter-spacing: 0px;
	color: #3b3b3b;
	opacity: 1;
	margin-top: 5px;
}

.transactionIcon {
	margin-right: 9px;
}

/*Success Modal*/
.successHeader {
	font-weight: bold;
	font-size: 1.2vmax;
	margin-bottom: 10px;
}

.successBody {
	font-size: 0.8vmax;
	margin-bottom: 30px;
}

.successModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}

/*Error Modal*/
.errorHeader {
	font-weight: bold;
	font-size: 0.9vmax;
	margin-bottom: 10px;
}

.errorBody {
	margin-top: 20px;
	font-size: 0.8vmax;
}

.errorFooter {
	margin-top: 0;
	font-size: 0.8vmax;
	margin-bottom: 30px;
}

.errorModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}

.tooltip {
	background: #f1f1f1 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 2px;
	color: #3b3b3b !important;
	font: normal normal 10px/16px Poppins-Light;
}

.tooltip > div {
	color: #3b3b3b !important;
	font: normal normal 10px/16px Poppins-Light;
}

.bodyHeaderEmphasis {
	font-weight: bold;
}

/* Details Section */
.sectionContainer {
	padding: 0;
	margin: 0;
}

.paperContainer {
	padding: 0;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.paperContainer > [role='table'] > [role='rowgroup'] > [role='row'] div {
	padding-bottom: 3px !important;
}
