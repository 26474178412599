.entryContainer {
	column-count: 2;
}

.entry {
	padding: 0.5em 0;
}

.readonly {
	opacity: 0.7;
}

