.input :global(.slds-input) {
	padding: 0.3vmax;
	padding-left: 1vw;
	font-size: var(--input-label-font-size);
	min-height: 0;
	line-height: 3vh;
}

.input :global(.slds-input[disabled]) {
	opacity: 0.7;
	color: #909090;
}

.input :global(.slds-input:read-only) {
	border-color: var(--sds-c-input-color-border, #dddbda) !important;
}

.input.multiLine {
	border: 1px solid var(--sds-c-input-color-border, #dddbda);
	border-radius: var(--sds-c-input-radius-border, 0.25rem);
	width: 100%;
	padding: 0.3vmax;
	padding-left: 1vw;
	line-height: 3vh;
	font-size: var(--input-label-font-size);
	border-color: var(--sds-c-input-color-border, #dddbda);
	background-color: #fff;
}

.input :global(.slds-form-element__help) {
	font-size: var(--input-label-font-size);
}

.fullWidth {
	width: 100%;
}

[data-placeholder]:empty {
	display: flex;
	align-items: center;
	cursor: text;
}

[data-placeholder]:empty:before {
	content: attr(data-placeholder);
	color: #73716f;
}

.input.multiLine.error {
	border: 2px solid #ea001e;
}

.input.multiLine:focus {
	box-shadow: var(--sds-c-input-shadow-focus, 0 0 3px #0176d3);
}

.input.multiLine.error:focus {
	box-shadow: #ea001e 0 0 0 1px inset, 0 0 3px #0176d3;
}

.textareaNonResizable {
	resize: none;
}

.helper {
	font-size: var(--input-label-font-size);
}

.uploadButton {
	margin-top: 2px;
}

.uploadBoxError {
	border: 2px solid #ea001e;
	border-radius: 5px;
}

.uploadBoxError:focus {
	box-shadow: #ea001e 0 0 0 1px inset, 0 0 3px #0176d3;
}

.alignCenter {
	display: flex;
	flex-direction: row;
}

.uploadField :global(.slds-spinner:before) {
	background-color: #f26122 !important;
}

.uploadField :global(.slds-spinner__dot-a:before) {
	background-color: #f26122 !important;
}

.uploadField :global(.slds-spinner__dot-b:before) {
	background-color: #f26122 !important;
}

.uploadField :global(.slds-spinner:after) {
	background-color: #f26122 !important;
}

.uploadField :global(.slds-spinner__dot-a:after) {
	background-color: #f26122 !important;
}

.uploadField :global(.slds-spinner__dot-b:after) {
	background-color: #f26122 !important;
}

.uploadFieldBatchUploadError :global(.slds-input:read-only) {
	border: 1px solid #d93025 !important;
	border-radius: 4px;
	opacity: 1;
}

.uploadFieldBatchUploadErrorText {
	margin-top: 13px;
}

.uploadFieldBatchUploadErrorText > span {
	text-align: left;
	font: normal normal normal 10px/16px Poppins-Regular;
	letter-spacing: 0px;
	color: #d93025;
	opacity: 1;
}

.textFieldContainer {
	position: relative;
}

.retryInput {
	height: 40px;
}

.retryIcon {
	position: absolute;
	top: 13px;
	right: 34px;
	fill: #73716f !important;
	cursor: pointer;
}

.tableInput :global(.slds-form-element__help) {
	position: absolute;
	font-size: 10px;
}
.longPlaceholder input::placeholder {
	font-size: 8px;
}
.placeholder {
	font-size: 12px;
}

.textField :global(.slds-input) {
	padding: 0.24vmax !important;
	padding-left: 1vw !important;
	font-size: var(--input-label-font-size) !important;
	min-height: 0 !important;
}

.disabledColor:disabled,
.disabledColor::placeholder {
	color: #c4c4c4;
}
