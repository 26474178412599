.modalContainer {
	width: 400px !important;
}

.customHeading {
	font-size: 16px;
	font-weight: bold;
}

.confirmationModalBtn {
	padding: 19px 6px !important;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}

.bodyHeader {
	padding: 15px;
	text-align: center;
	align-items: center;
	justify-content: center;
	font-size: 13px;
	font-family: Poppins-Regular;
}
.bodyContent {
	padding: 0 15px 15px 15px;
}

.remarks {
	resize: none;
	height: 100px;
}

.bodyContent :global(.slds-form-element__label) {
	font-size: 11px !important;
	font-family: Poppins-Regular;
	font-weight: 400 !important;
}

.remarksSubLabel {
	font-size: 11px;
	font-family: Poppins-Regular;
	color: #8b9095 !important;
}

.btnStyleClass {
	float: right !important;
	margin: 1.9vh;
	margin-top: -1vh !important;
}
