.checkboxContainer {
	border: 1px solid #dadce0;
	border-radius: 5px;
	padding: 0.5vmax;
	font-family: var(--common-font);
	max-width: 57.5vw;
}

.toolTip {
	display: block !important;
	line-height: normal !important;
	margin-left: 0.03vw !important;
}

.toolTip > div[role='tooltip'] {
	background: #f1f1f1 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 2px;
	font: normal normal 10px/16px Poppins-Light;
	margin-left: -5.8vw !important;
}

.toolTip > div[role='tooltip'] > * {
	color: #3b3b3b !important;
}

.selectAllContainer {
	padding: 0.7vmax;
}

.checklistBody {
	padding: 0.5vmax;
}

.checkbox.checked :global(.slds-checkbox_faux:after) {
	border-color: white !important;
}

.checkbox.checked :global(.slds-checkbox_faux) {
	background-color: var(--orange) !important;
}
.entry {
	margin: 1vh 0;
}

.disabledChkBox {
	opacity: 0.7;
}

.disabledChkBox label > span {
	color: #a9acad;
}

.listBody {
	overflow-y: auto;
	padding: 0.3vmax;
	height: 15vh;
}

.noResult {
	gap: 14px;
	width: 30%;
	display: flex;
	margin-left: auto;
	margin-right: auto;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}

.noResult img {
	height: 51px;
}

.noResult span {
	color: #f26122;
}

.helper {
	font-size: var(--input-label-font-size);
}

.errorCheckbox {
	border: 2px solid #ea001e;
}
@media screen and (max-width: 1920px) and (min-width: 1367px) {
	.searchProductType {
		max-width: 40.5vw;
	}
}
@media (max-width: 1366px) {
	.searchProductType {
		max-width: 53.5vw;
	}
}
