.title {
	text-align: left;
	font-family: var(--common-font-bold);
	font-size: 1.2vw;
	width: 100%;
	margin-bottom: 2vh;
}

.select :global(.slds-button) {
	font-size: 0.7vw !important;
	line-height: 0.7vw !important;
	border-radius: 16px;
	color: white !important;
	padding: 0.5vh 0.4vw !important;
	height: 15px;
	background-color: #8b9095;
}

.active :global(.slds-button) {
	background-color: #14bb02;
}

.active a,
.active a:hover {
	color: #14bb02;
}

.statusLabel {
	font: normal 0.8vw Poppins-Regular;
}

.titleActionTextContainer {
	display: block;
}

.titleActionText {
	color: #8b9095;
	font-size: 0.8vw;
	margin-top: 5px;
}

.linkBtn {
	border: none !important;
	padding-right: 0;
	color: orange !important;
	font: bold 12px Poppins-Regular !important;
	background: none !important;
}

.edit svg {
	margin-right: 0.5vw;
	width: 1vmax;
	height: 1vmax;
}
