.headerContainer {
	border: none;
	padding-bottom: 0;
}

.modal {
	font-family: var(--common-font);
	overflow-y: auto;
}

.header {
	overflow: auto;
	overflow-y: hidden;
	padding-top: 1vmax;
	padding-left: 0.5vmax;
	padding-right: 0.5vmax;
}

.titleActionBtnContainer {
	display: block;
	display: flex;
	justify-content: right;
	min-height: 1.8vw;
}

.btn {
	padding: 19px 6px !important;
	min-width: 120px !important;
	font-size: 13px !important;
	word-wrap: break-word;
	border-radius: 4px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
}

.btn:hover .btnIcon :global(path),
.btn:active .btnIcon :global(path),
.btn:focus .btnIcon :global(path) {
	fill: #ffffff;
}

.titleActionTextContainer {
	display: block;
}

.titleActionText {
	color: #8b9095;
	font-size: 0.8vw;
	margin-top: 5px;
}

.titleRightPanel {
	float: right;
	display: flex;
	justify-content: right;
	flex-direction: column;
}

.titleLeftPanel {
	float: left;
	display: flex;
	justify-content: left;
	flex-direction: column;
}

.footer {
	overflow: auto;
	overflow-y: hidden;
}

.footerRightPanel {
	float: right;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
