.title {
	color: var(--orange);
	font-size: 1.2vw;
	font: normal 600 24px/24px var(--alt-font);
	letter-spacing: 0.74px;
	margin-bottom: 15px;
}

.paperContainer {
	padding: 1vmax;
	margin-top: 41px;
	border-radius: 10px;
}

.mainContainer {
	margin-top: 13px;
}

.innerButtons {
	padding-top: 2.2em;
}

.iconBtn {
	width: 1vw;
	height: 1vw;
}

.rowBreak {
	word-break: break-all;
}

.downloadDropdown {
	opacity: 0;
	pointer-events: none;
	position: fixed;
	right: 2%;
	top: 12%;
}

.downloadContainer {
	position: relative;
}

.errorBodyContent {
	padding-left: 15px;
	padding-right: 15px;
}

.errorHeader {
	font-weight: bold;
	font-size: 1.2vmax;
	margin-bottom: 10px;
}

.errorBody {
	font-size: 0.8vmax;
}

.errorFooter {
	margin-top: 0;
	font-size: 0.8vmax;
	margin-bottom: 30px;
}

.errorModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}

.successHeader {
	font-weight: bold;
	font-size: 1.2vmax;
	margin-bottom: 10px;
}

.successBody {
	font-size: 0.8vmax;
	margin-bottom: 30px;
}

.successModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}

.filters > div,
.innerButtons > div {
	padding-left: 0.2em;
	padding-right: 0.2em;
}

.crossIcon,
.crossIcon:focus {
	color: var(--orange);
}

.crossIcon:hover {
	color: var(--darkerOrange);
}

.actionCell {
	width: 100%;
}

.actionBtnContainer {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.btnApprove,
.btnReject {
	background-color: #ffffff;
	padding: 19px 6px;
	min-width: 120px;
	font-size: 13px !important;
	border-radius: 4px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
}

.btnApproveIcon,
.btnRejectIcon {
	margin-right: 10px;
}

.btnApprove,
.btnApprove:focus,
.btnApprove:active,
.btnApprove:hover {
	color: #14bb02;
	border: 1px solid #14bb02;
}

.btnReject,
.btnReject:focus,
.btnReject:active,
.btnReject:hover {
	color: #d93025;
	border: 1px solid #d93025;
}

.modalContainer {
	width: 400px !important;
}

.customHeading {
	font-size: 16px;
	font-weight: bold;
}

.bodyHeader {
	padding: 15px;
	text-align: center;
	align-items: center;
	justify-content: center;
	font-size: 13px;
	font-family: Poppins-Regular;
}

.bodyContent {
	padding: 0 15px 15px 15px;
}

.remarks {
	resize: none;
	height: 100px;
}

.bodyContent :global(.slds-form-element__label) {
	font-size: 11px !important;
	font-family: Poppins-Regular;
	font-weight: 400 !important;
}

.remarksSubLabel {
	font-size: 11px;
	font-family: Poppins-Regular;
	color: #8b9095 !important;
}

.approvalModalFooter,
.rejectionModalFooter {
	padding-top: 5px;
	padding-bottom: 5px;
}

.approvalModalBtn,
.rejectionModalBtn {
	padding: 19px 6px !important;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}

.centerAlign {
	text-align: center;
	margin-right: 0 !important;
	width: 100%;
}

.leftAlign {
	text-align: left;
	margin-right: 0 !important;
	width: 100%;
	margin-top: 30px;
}

.preHeaderLabel {
	font-size: min(0.8vw, 16px) !important;
	color: white !important;
}

.toolTip {
	width: 100%;
}

.toolTip > div {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.toolTip > div > div {
	color: #3b3b3b !important;
	font: normal normal 10px/16px Poppins-Light;
}

.approveHeading {
	font-size: 14px;
	font-weight: 600;
	font-stretch: condensed;
}

.boldText {
	font-family: var(--common-font-bold);
}


.table {
	border: solid black 1px;
}

.Rejected {
	color: red;
}
.Approved {
	color:#14bb02
}
.ForReview{
	color:var(--orange)
}